import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OAuthSnackbars } from '../types/o-auth-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class OAuthSnackbarsService {
  private snackbar = inject(MatSnackBar);
  private translate = inject(TranslateService);

  failureLogin() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLogin.message),
      OAuthSnackbars.failureLogin.closeBtn,
      OAuthSnackbars.failureLogin.config
    );
  }

  successChangePassword() {
    this.snackbar.open(
      OAuthSnackbars.successChangePassword.message,
      OAuthSnackbars.successChangePassword.closeBtn,
      OAuthSnackbars.successChangePassword.config
    );
  }

  failureChangePasswordOldInvalid() {
    this.snackbar.open(
      OAuthSnackbars.failureChangePasswordOldInvalid.message,
      OAuthSnackbars.failureChangePasswordOldInvalid.closeBtn,
      OAuthSnackbars.failureChangePasswordOldInvalid.config
    );
  }

  failureChangePasswordDifferents() {
    this.snackbar.open(
      OAuthSnackbars.failureChangePasswordDifferents.message,
      OAuthSnackbars.failureChangePasswordDifferents.closeBtn,
      OAuthSnackbars.failureChangePasswordDifferents.config
    );
  }

  failureDeleteClientItHasOperations() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureDeleteClientItHasOperations.message
      ),
      OAuthSnackbars.failureDeleteClientItHasOperations.closeBtn,
      OAuthSnackbars.failureDeleteClientItHasOperations.config
    );
  }

  failureDeleteMerchantItHasOperations() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureDeleteMerchantItHasOperations.message
      ),
      OAuthSnackbars.failureDeleteMerchantItHasOperations.closeBtn,
      OAuthSnackbars.failureDeleteMerchantItHasOperations.config
    );
  }

  failureChangePassword4Characters() {
    this.snackbar.open(
      OAuthSnackbars.failureChangePassword4Characters.message,
      OAuthSnackbars.failureChangePassword4Characters.closeBtn,
      OAuthSnackbars.failureChangePassword4Characters.config
    );
  }

  failureLoginCancelled() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginCancelled.message),
      OAuthSnackbars.failureLoginCancelled.closeBtn,
      OAuthSnackbars.failureLoginCancelled.config
    );
  }

  failureLoginInactive() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginInactive.message),
      OAuthSnackbars.failureLoginInactive.closeBtn,
      OAuthSnackbars.failureLoginInactive.config
    );
  }

  failureChangePasswordOldPassword() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureChangePasswordOldPassword.message
      ),
      OAuthSnackbars.failureChangePasswordOldPassword.closeBtn,
      OAuthSnackbars.failureChangePasswordOldPassword.config
    );
  }

  failureChangePasswordSame() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePasswordSame.message),
      OAuthSnackbars.failureChangePasswordSame.closeBtn,
      OAuthSnackbars.failureChangePasswordSame.config
    );
  }

  failureChangePassword() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePassword.message),
      OAuthSnackbars.failureChangePassword.closeBtn,
      OAuthSnackbars.failureChangePassword.config
    );
  }

  failureChangePhone() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePhone.message),
      OAuthSnackbars.failureChangePhone.closeBtn,
      OAuthSnackbars.failureChangePhone.config
    );
  }

  failureUserStatusIsNotCreated() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureUserStatusIsNotCreated.message
      ),
      OAuthSnackbars.failureUserStatusIsNotCreated.closeBtn,
      OAuthSnackbars.failureUserStatusIsNotCreated.config
    );
  }

  failureChangePhoneGeneralMessage() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureChangePhoneGeneralMessage.message
      ),
      OAuthSnackbars.failureChangePhoneGeneralMessage.closeBtn,
      OAuthSnackbars.failureChangePhoneGeneralMessage.config
    );
  }

  failureAccountNoValidate() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureAccountNoValidate.message),
      OAuthSnackbars.failureAccountNoValidate.closeBtn,
      OAuthSnackbars.failureAccountNoValidate.config
    );
  }
}
