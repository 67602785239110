import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorsHttpService {
  private router = inject(Router);

  public communication(error: HttpErrorResponse): void {
    this.manageError(error, 'ERROR HTTP');
    if (
      error &&
      error.status &&
      (error.status === 401 || error.status === 403)
    ) {
      this.router.navigate(['/auth/logout']);
    }
  }

  public apiInvalidResponse(error: object): void {
    this.manageError(error, 'INVALID API RESPONSE');
  }

  private manageError(error: object, text?: string): void {
    // TODO:
    console.error(`${text} `, error);
  }

  public isControlledError(error: HttpErrorResponse): boolean {
    if (
      error?.ok === false &&
      error?.status === 400 &&
      error?.error?.errors?.length &&
      Object.prototype.hasOwnProperty.call(error?.error?.errors[0], 'code') &&
      Object.prototype.hasOwnProperty.call(error?.error?.errors[0], 'title') &&
      Object.prototype.hasOwnProperty.call(error?.error?.errors[0], 'detail')
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isErrorCode(
    error: HttpErrorResponse,
    code: string,
    title?: string,
    detail?: string
  ): boolean {
    if (
      this.isControlledError(error) &&
      error?.error?.errors[0]?.code === code
    ) {
      if (!title && !detail) {
        return true;
      } else {
        if (
          error?.error?.errors[0]?.title === title &&
          error?.error?.errors[0]?.detail === detail
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
}
