import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ErrorsHttpService } from '@qaroni-core/services/comms/errors-http/errors-http.service';
import { OAuthSnackbarsService } from './o-auth-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthErrorService {
  private errorsHttp = inject(ErrorsHttpService);
  private snackbars = inject(OAuthSnackbarsService);

  errorLogin(error: HttpErrorResponse) {
    if (this.errorsHttp.isErrorCode(error, 'E0012')) {
      this.snackbars.failureLogin();
    } else if (this.errorsHttp.isErrorCode(error, 'E0013')) {
      this.snackbars.failureLoginCancelled();
    } else if (this.errorsHttp.isErrorCode(error, 'E0014')) {
      this.snackbars.failureLoginInactive();
    } else if (this.errorsHttp.isErrorCode(error, 'E0020')) {
      this.snackbars.failureAccountNoValidate();
    }
  }

  errorChangePassword(error: HttpErrorResponse) {
    if (this.errorsHttp.isErrorCode(error, 'E0010')) {
      this.snackbars.failureChangePasswordOldPassword();
    } else if (this.errorsHttp.isErrorCode(error, 'E0004')) {
      this.snackbars.failureChangePasswordSame();
    } else {
      this.snackbars.failureChangePassword();
    }
  }

  errorUpdateUserRegister(error: HttpErrorResponse) {
    if (this.errorsHttp.isErrorCode(error, 'E0006')) {
      this.snackbars.failureChangePhone();
    } else if (this.errorsHttp.isErrorCode(error, 'E0008')) {
      this.snackbars.failureUserStatusIsNotCreated();
    } else {
      this.snackbars.failureChangePhoneGeneralMessage();
    }
  }
}
