<div class="flex justify-end">
  <button
    mat-button
    type="button"
    class="qaroni-close-icon"
    [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div>
  <div mat-dialog-title>
    {{ 'Se ha producido un error en la aplicación' | translate }}.
  </div>
  <mat-dialog-content>
    <p>
      {{
        'Es posible que el error sea algo temporal, por lo que puedes intentarlo de nuevo'
          | translate
      }}.
    </p>
    <p>
      {{ 'Si no se soluciona el problema contacta con nosotros' | translate }}.
      @if (!showErrorDetails) {
        <span (click)="onShowErrorDetails()">
          <mat-icon
            class="more-info-icon"
            [matTooltip]="'Más información del error' | translate"
            >error_outline</mat-icon
          >
        </span>
      }
    </p>
    @if (showErrorDetails) {
      <mat-card class="shadow-none">
        <mat-card-header>
          <mat-card-title>{{
            'Información técnica del error' | translate
          }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{
              'El siguiente código contiene información útil para nuestros técnicos a la hora de detectar y corregir el error'
                | translate
            }}.
          </p>
          <code>
            @if (isControlledError) {
              <div class="mb-3">
                <strong
                  >{{ error?.error?.errors[0]?.code }}:
                  {{ error?.error?.errors[0]?.title }}</strong
                >
                @if (error?.error?.errors[0]?.detail) {
                  <em class="pl-1">{{ error?.error?.errors[0]?.detail }}</em>
                }
              </div>
            }
            <div><strong>Url:</strong> {{ error?.url }}</div>
            <div><strong>Status:</strong> {{ error?.status }}</div>
            <div><strong>StatusText:</strong> {{ error?.statusText }}</div>
            <div><strong>Message:</strong> {{ error?.message }}</div>
          </code>
        </mat-card-content>
      </mat-card>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      color="warn"
      type="button"
      [mat-dialog-close]="true"
      cdkFocusInitial>
      <mat-icon>error</mat-icon> {{ 'Cerrar' | translate }}
    </button>
  </mat-dialog-actions>
</div>
