import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorsHttpService } from '@qaroni-core/services/comms/errors-http/errors-http.service';

@Component({
  selector: 'qaroni-errors-http-dialog',
  templateUrl: './errors-http-dialog.component.html',
  styleUrls: ['./errors-http-dialog.component.scss'],
})
export class ErrorsHttpDialogComponent {
  public showErrorDetails = false;

  public dialogRef = inject(MatDialogRef<ErrorsHttpDialogComponent>);
  public error: HttpErrorResponse = inject(MAT_DIALOG_DATA);
  private errorsHttp = inject(ErrorsHttpService);

  public onShowErrorDetails(): void {
    this.showErrorDetails = true;
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  get isControlledError(): boolean {
    return this.errorsHttp.isControlledError(this.error);
  }
}
