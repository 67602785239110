import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { QaroniIntermediate } from '@qaroni-core/types/qaroni-intermediate/qaroni-intermediate';
import { shareReplay, Subject } from 'rxjs';
import { OAuthApiResponse, UserApiResponse } from '../types/auth-response';
import {
  ChangePasswordJson,
  LoginDTO,
  OtpUsernameJson,
} from '../types/authentication';
import { OAuth } from '../types/o-auth';
import { User } from '../types/user';
import { OAuthErrorService } from './o-auth-error.service';
import { OAuthHttpService } from './o-auth-http.service';
import { OAuthSnackbarsService } from './o-auth-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthService extends QaroniIntermediate {
  protected readonly oAuthSubject = new Subject<OAuth>();
  protected readonly userSubject = new Subject<User>();
  protected readonly usersSubject = new Subject<User[]>();

  public snackbars = inject(OAuthSnackbarsService);
  private oAuthHttp = inject(OAuthHttpService);
  private oAuthErrors = inject(OAuthErrorService);

  get getUserID(): number {
    return this.storage.getUserID;
  }

  get getClientID(): number {
    return this.storage.getClientID;
  }

  get getMerchantID(): number {
    return this.storage.getMerchantID;
  }

  // ==========================================================================================

  public getUser$() {
    return this.userSubject.asObservable().pipe(shareReplay(1));
  }

  // ==========================================================================================

  public getOAuth$() {
    return this.oAuthSubject.asObservable().pipe(shareReplay(1));
  }

  public login(loginDTO: LoginDTO): void {
    this.oAuthHttp
      .login$(loginDTO)
      .subscribe({ next: this.nextLogin, error: this.errorLogin });
  }

  private nextLogin = (data: OAuthApiResponse): void => {
    if (this.status200(data)) {
      const oAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorLogin = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorLogin(error);
    this.oAuthSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public otpUsername(otpUsernameJSON: OtpUsernameJson): void {
    this.oAuthHttp
      .otpUsername$(otpUsernameJSON)
      .subscribe({ next: this.nextOtpUsername, error: this.errorOtpUsername });
  }

  private nextOtpUsername = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorOtpUsername = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public changePassword(changePasswordJSON: ChangePasswordJson): void {
    if (!(this.storage.hasOAuth && this.storage.getUserID)) {
      return;
    }

    this.oAuthHttp
      .changePassword$(this.storage.getUserID, changePasswordJSON)
      .subscribe({
        next: this.nextChangePassword,
        error: this.errorChangePassword,
      });
  }

  private nextChangePassword = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);

      this.snackbars.successChangePassword();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorChangePassword = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    if (
      this.errors.isControlledError(error) &&
      this.errors.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'oldPassword es inválida'
      )
    ) {
      this.snackbars.failureChangePasswordOldInvalid();
    } else if (
      this.errors.isControlledError(error) &&
      this.errors.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The new password must be at least 4 characters.'
      )
    ) {
      this.snackbars.failureChangePassword4Characters();
    } else if (
      this.errors.isControlledError(error) &&
      this.errors.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The new password and old password must be different.'
      )
    ) {
      this.snackbars.failureChangePasswordDifferents();
    } else if (
      this.errors.isControlledError(error) &&
      this.errors.isErrorCode(
        error,
        'E0010',
        'Invalid information in the request.',
        ''
      )
    ) {
      this.snackbars.failureChangePasswordOldInvalid();
    }
    this.errors.communication(error);
  };

  // ==========================================================================================

  public deleteUser(userID: number | string): void {
    this.oAuthHttp
      .deleteUser$(userID)
      .subscribe({ next: this.nextDeleteUser, error: this.errorDeleteUser });
  }

  private nextDeleteUser = (data: UserApiResponse): void => {
    if (this.status204(data)) {
      this.messageSubject.next('Eliminao pelao');
    } else {
      this.messageSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorDeleteUser = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    if (
      this.errors.isControlledError(error) &&
      this.errors.isErrorCode(
        error,
        'E0022',
        `User cannot be deleted.`,
        'No se puede borrar al cliente, tiene operaciones asociadas'
      )
    ) {
      this.snackbars.failureDeleteClientItHasOperations();
    }
    if (
      this.errors.isControlledError(error) &&
      this.errors.isErrorCode(
        error,
        'E0022',
        `User cannot be deleted.`,
        'No se puede borrar al comercio, tiene operaciones asociadas'
      )
    ) {
      this.snackbars.failureDeleteMerchantItHasOperations();
    }
    this.errors.communication(error);
  };

  // ==========================================================================================

  public getUsers$() {
    return this.usersSubject.asObservable().pipe(shareReplay(1));
  }

  public getUsers(params?: Params): void {
    if (!this.hasOAuth) {
      return;
    }

    this.oAuthHttp.getUsers$(params).subscribe({
      next: this.nextGetUsers,
      error: this.errorGetUsers,
    });
  }

  private nextGetUsers = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const users = data.body.result;
      this.usersSubject.next(users);
    }
  };

  private errorGetUsers = (error: HttpErrorResponse): void => {
    this.errors.communication(error);
  };
}
