import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum UserRoleEnum {
  CLIENT = 'CLIENT',
  MERCHANT = 'MERCHANT',
  TOWN_HALL = 'TOWN_HALL',
}

export enum RoleFilterEnum {
  CLIENT = 'CLIENT',
  MERCHANT = 'MERCHANT',
}

export const UserRoleArray = [
  UserRoleEnum.CLIENT,
  UserRoleEnum.MERCHANT,
  UserRoleEnum.TOWN_HALL,
];

export const UserRoleFilterArray = [UserRoleEnum.CLIENT, UserRoleEnum.MERCHANT];

export const UserRoleInfo: QaroniEnumInformation<UserRoleEnum> = {
  [UserRoleEnum.CLIENT]: {
    name: 'Cliente',
  },

  [UserRoleEnum.MERCHANT]: {
    name: 'Comerciante',
  },

  [UserRoleEnum.TOWN_HALL]: {
    name: 'Administrador',
  },
};
